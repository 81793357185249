<template>
  <div>
    <div class="text-right">
      <v-btn
        data-cy="add_bond"
        fab
        depressed
        outlined
        x-small
        color="hqsOrange"
        class="mb-5"
        @click="addBond"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <!-- bonds table -->
    <v-data-table
      dense
      :headers="headers"
      :items="$route.name.includes('spin') ? getTableData : getBondsTable"
      :item-class="classForItem"
    >
      <template v-slot:top>
        <v-spacer></v-spacer>
        <!-- bonds form dialog -->
        <v-dialog data-cy="bond_dialog" v-model="dialog" max-width="800px" persistent>
          <v-card>
            <v-toolbar flat class="hqsOrange--text text">
              <v-toolbar-title v-model="title"
                >{{ title }} Bond</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-btn data-cy="close_bonds_dialog" icon @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <BondsForm
                  @closeDialogOnEdit="closeDialogOnEdit"
                  v-bind:title="title"
                  @dialog="!dialog"
                ></BondsForm>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon data_cy="edit_bond" small class="mr-2" @click="editBond(item)"
          >mdi-pencil</v-icon
        >
        <v-icon small @click="askConfirmation(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <!-- configure all j-values for spins only -->
    <v-row v-if="$route.name.includes('spin')">
      <v-spacer></v-spacer>
      <JValuesDialog></JValuesDialog>
      <InfoDialog info="jValues"></InfoDialog>
    </v-row>
    <ConfirmDialog
      :visible="showConfirmDialog"
      @close="showConfirmDialog = false"
      @remove="deleteBond"
    />
  </div>
</template>

<script>
const ConfirmDialog = () =>
  import(/* webpackChunkName: "dialogs" */ '../general/ConfirmDialog')
const JValuesDialog = () =>
  import(/* webpackChunkName: "dialogs" */ './JValuesDialog.vue')
const InfoDialog = () =>
  import(/* webpackChunkName: "dialogs" */ './InfoDialog.vue')
const BondsForm = () =>
  import(/* webpackChunkName: "dialogs" */ './BondsForm.vue')
import { createNamespacedHelpers } from 'vuex'

const { mapMutations, mapGetters, mapState } =
  createNamespacedHelpers('lattice')

export default {
  components: {
    JValuesDialog,
    InfoDialog,
    BondsForm,
    ConfirmDialog,
  },

  data() {
    return {
      itemToDelete: {},
      showConfirmDialog: false,
      emptyForm: '',
      title: '',
      dialog: false,
      pagination: {},
      editedIndex: -1,
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  methods: {
    ...mapMutations([
      'prepareAddBond',
      'setEditBondIndex',
      'prepareEditBond',
      'delete',
      'resetBondID',
      'resetAddOneMore',
    ]),

    closeDialogOnEdit(title) {
      if (title === 'Edit' || !this.addOneMore) {
        this.dialog = false
      }
    },

    classForItem(item) {
      if (item.id === this.selectedBond) {
        return ['highlight']
      } else {
        return ['']
      }
    },

    addBond() {
      if (this.latticeType != '' && this.latticeType != null) {
        this.title = 'Add'
        this.prepareAddBond()
      }
      this.dialog = true
    },

    editBond(item) {
      this.title = 'Edit'
      this.prepareEditBond(item.id)
      this.dialog = true
    },

    askConfirmation(item) {
      this.itemToDelete = item
      this.showConfirmDialog = true
    },

    deleteBond() {
      let type = 'bond'
      this.delete([this.itemToDelete.id, type])
      this.showConfirmDialog = false
    },

    close() {
      this.dialog = false
      this.resetBondID()
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.setEditBondIndex('')
      })
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.bonds[this.editedIndex], this.editedItem)
      } else {
        this.bonds.push(this.editedItem)
      }
      this.close()
    },
  },

  computed: {
    ...mapGetters(['getBondsTable']),

    ...mapState({
      selectedBond: (state) => state.currentBond.id,
      bonds: (state) => state.bonds,
      addOneMore: (state) => state.addOneMore,
      latticeType: (state) => state.latticeType,
    }),

    /* only for spins */
    getTableData() {
      return this.getBondsTable
    },

    headers() {
      let headers = [
        { text: 'From', align: 'start', sortable: false, value: 'id_from' },
        { text: 'To', sortable: false, value: 'id_to' },
        { text: 'Translation', sortable: false, value: 'translation' },
      ]
      if (this.latticeType.includes('spinless')) {
        headers = headers.concat([
          { text: 't', sortable: false, value: 't' },
          /* currently not used because qolossal does not need it */
          /* { text: 'U', sortable: false, value: 'U' }, */
          { text: 'D', sortable: false, value: 'D' },
        ])
      }
      /* currently not used because qolossal does not need it */
      /* if (this.latticeType.includes('spinful')) {
        headers = headers.concat([{ text: 'U', sortable: false, value: 'U' }])
      } */
      headers.push({
        text: 'Actions',
        value: 'actions',
        sortable: false,
        align: 'end',
      })
      return headers
    },

    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Bond'
    },
  },

  created() {
    this.resetAddOneMore()
  },
}
</script>

<style lang="css" scoped></style>
